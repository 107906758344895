import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, CircularProgress, Box } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import api from '../../../../services/api';
import { blue } from '@mui/material/colors';

const AverageUsageDelay = () => {
    const [data, setData] = useState({ average_usage_delay: '' });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        // api.get('/api/ibondacha/vouchers/average-usage-delay', { timeout: 10000 })
        api.get('/api/ibondacha/vouchers/average-usage-delay')
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données', error);
                setError(true);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography variant="h6" color="error">
                    <ErrorOutlineIcon /> Erreur lors de la récupération des données.
                </Typography>
            </Box>
        );
    }

    return (
        <Card elevation={3}>
            <CardContent>
                <Box display="flex" alignItems="center">
                    <AccessTimeIcon fontSize="large" sx={{ color: blue[500] }} />
                    <Box ml={2}>
                        <Typography variant="h6">Délai Moyen d'Utilisation</Typography>
                        <Typography variant="h4" color="primary">{data.average_usage_delay}</Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default AverageUsageDelay;