import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, CircularProgress, Box, Grid } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import api from '../../../../services/api';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { teal, orange, blue, green, red, purple, pink } from '@mui/material/colors';

const BeneficiaryStats = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        api.get('/api/ibondacha/vouchers/beneficiary-stats/')  
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données', error);
                setError(true);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography variant="h6" color="error">
                    <ErrorOutlineIcon /> Erreur lors de la récupération des données.
                </Typography>
            </Box>
        );
    }

    // Tableau de couleurs pour attribuer une couleur à chaque entreprise
    const colors = [teal[500], orange[500], blue[500], green[500], red[500], purple[500], pink[500]];

    return (
        <Grid container spacing={3}>
            {data.map((beneficiary, index) => (
                <Grid item xs={12} md={6} lg={4} key={index}>
                    <Card elevation={3}>
                        <CardContent>
                            <Box display="flex" alignItems="center">
                                <BusinessIcon fontSize="large" sx={{ color: colors[index % colors.length] }} />
                                <Box ml={2}>
                                    <Typography variant="h6">{beneficiary.beneficiary_name}</Typography>
                                    <Typography variant="body1" color="textSecondary">Total iBondacha :</Typography>
                                    <Typography variant="h5" color="primary">
                                        {Number(beneficiary.total_vouchers).toLocaleString('fr-FR', { style: 'currency', currency: 'MGA' })}
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary">Total iBondacha Actifs :</Typography>
                                    <Typography variant="h5" color="primary">
                                        {Number(beneficiary.total_active_vouchers).toLocaleString('fr-FR', { style: 'currency', currency: 'MGA' })}
                                    </Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default BeneficiaryStats;