import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import { Dialog, DialogTitle, Paper, DialogContent, Backdrop, Snackbar, Alert, Button, Grid, Box, CircularProgress, IconButton, Typography, Stack } from '@mui/material';
import { Refresh, Visibility as VisibilityIcon } from '@mui/icons-material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { Helmet } from 'react-helmet-async';
import api from '../../../../services/api';
import WidgetSMS from './WidgetSMS';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

function isFutureDate(date) {
  const now = new Date();
  const launchDate = new Date(date);
  return launchDate > now;
}

function DisplayLaunchDate({ sendAt }) {
  if (sendAt && isFutureDate(sendAt)) {
    const formattedDate = dayjs(sendAt).tz('Europe/Moscow').format('YYYY-MM-DD HH:mm:ss');
    return (
      <Typography color="primary" textAlign="center" m={1}>
        <b>SMS programmés | Lancement :</b> {formattedDate}
      </Typography>
    );
  }
}

const getMessageLength = (message) => {
  return message ? message.split('').reduce((count, char) => count + (char === 'ê' || char === 'ô' ? 2 : 1), 0) : 0;
};

const getSmsCount = (messageLength) => {
  if (messageLength <= 160) {
    return 1;
  } else if (messageLength <= 320) {
    return 2;
  }
  return Math.ceil(messageLength / 160);
};

function CampaignsList() {
  const [campaigns, setCampaigns] = useState([]);
  const [campaignsFuture, setCampaignsFuture] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [campaignStats, setCampaignStats] = useState({});
  const [campaignInfos, setCampaignInfos] = useState({});
  const [openDetails, setOpenDetails] = useState(false);
  const [openDetailsFuture, setOpenDetailsFuture] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoadingStats, setIsLoadingStats] = useState(false);

  const [canGoCampaign, setCanGoCampaign] = useState(false);
  const [goCampaign, setGoCampaign] = useState(false);
  const [msgCampaign, setMsgGoCampaign] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    fetchCampaigns();
    fetchCampaignsFuture();
  }, []);

  const fetchCampaigns = async () => {
    setLoading(true);
    try {
      const response = await api.get('/api/smsko/sms/campaigns/?active=true');
      setCampaigns(response.data);
    } catch (error) {
      console.error('Failed to fetch campaigns', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCampaignsFuture = async () => {
    setLoading(true);
    try {
      const response = await api.get('/api/smsko/sms/campaigns/?active=false');
      setCampaignsFuture(response.data);
    } catch (error) {
      console.error('Failed to fetch campaigns', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCampaignStats = async (campaignId) => {
    setIsLoadingStats(true); 
    try {
      const response = await api.get(`/api/smsko/sms/campaign/${campaignId}/statistics/`);
      setCampaignStats(response.data);
    } catch (error) {
      // console.error('Failed to fetch campaign statistics', error);
      setCampaignStats({});
    } finally {
      setIsLoadingStats(false); 
    }
  };

  const fetchCampaignStatsNoLoad = async (campaignId) => {
    try {
      const response = await api.get(`/api/smsko/sms/campaign/${campaignId}/statistics/`);
      setCampaignStats(response.data);
    } catch (error) {
      // console.error('Failed to fetch campaign statistics', error);
      setCampaignStats({});
    } finally {
      // 
    }
  };

  const handleOpenDetails = (campaign) => {
    setSelectedCampaign(campaign);
    fetchCampaignStats(campaign.id);
    setOpenDetails(true);
  };

  const handleOpenDetailsFuture = (campaign) => {
    setSelectedCampaign(campaign);
    fetchCampaignStats(campaign.id);
    setOpenDetailsFuture(true);
  };

  useEffect(() => {
    let intervalId;
    if (openDetails && selectedCampaign.id) {
      intervalId = setInterval(() => {
        fetchCampaignStatsNoLoad(selectedCampaign.id);
      }, 3000); 
    }
  
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [openDetails, selectedCampaign.id]);


  const columnsfuture = [
    { field: 'name', headerName: 'Campagne', width: 200 },
    { field: 'message', headerName: 'Message', flex: 1 },
    {
      field: 'details',
      headerName: 'Action',
      width: 130,
      renderCell: (params) => (
        <IconButton onClick={() => handleOpenDetailsFuture(params.row)}>
          <RocketLaunchIcon color='primary' />
        </IconButton>
      ),
      sortable: false,
      filterable: false,
    }
  ];

  const columns = [
    { field: 'name', headerName: 'Campagne', width: 200 },
    { field: 'message', headerName: 'Message', flex: 1 },
    {
      field: 'details',
      headerName: 'Détails',
      width: 130,
      renderCell: (params) => (
        <IconButton onClick={() => handleOpenDetails(params.row)}>
          <VisibilityIcon />
        </IconButton>
      ),
      sortable: false,
      filterable: false,
    }
  ];

  const preLauchCampaign = async (campaignId) => {
    setGoCampaign(true);
    try {
      const response = await api.post(`/api/smsko/sms/campaigns/launch/${campaignId}/`);
      setCampaignInfos(response.data);

      const smsCount = getSmsCount(getMessageLength(response.data.message)) * response.data.recipients_count;
      if (response.data.current_balance >= smsCount) {
        setCanGoCampaign(true);
      } else {
        setCanGoCampaign(false);
      }
    } catch (error) {
      console.error('Action Failed', error);
    } finally {
      setGoCampaign(false);
    }
  };

  const LauchCampaign = async (campaignId) => {
    setGoCampaign(true);
    try {
      const response = await api.post(`/api/smsko/sms/campaigns/launch/${campaignId}/`, {
        confirm: true
      });
      setMsgGoCampaign(response.data.message);
      setOpenDetailsFuture(false);
    } catch (error) {
      console.error('Action Failed', error);
    } finally {
      setGoCampaign(false);
      setSnackbarOpen(true);
      fetchCampaignsFuture();
      fetchCampaigns();
    }
  };

  const delCampaign = async (campaignId) => {
    setGoCampaign(true);
    try {
      await api.delete(`/api/smsko/sms/campaigns/${campaignId}/delete/`);
    } catch (error) {
      console.error('Action Failed', error);
    } finally {
      setGoCampaign(false);
      setSnackbarOpen(true);
      setMsgGoCampaign("La campagne a été supprimée");
      setOpenDetailsFuture(false);
      fetchCampaignsFuture();
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Helmet>
        <title>Campagnes SMS - BEFIANA Connect</title>
        <meta name="description" content="Gérer et suivre vos campagnes SMS sur BEFIANA Connect." />
      </Helmet>
      
      <Paper elevation={1} sx={{ margin: 'auto', mt: 1, mb: 1, p: 3 }}>
        <Typography variant="h3" color="secondary">Mes campagnes à lancer</Typography>
        <Button variant="outlined" color="primary" sx={{ mb: 1 }} startIcon={<Refresh />} onClick={fetchCampaignsFuture}>
          Actualiser
        </Button>
        {loading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            rows={campaignsFuture}
            columns={columnsfuture}
            pageSize={10}
            rowsPerPageOptions={[10, 20, 50]}
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            sx={{ minHeight: '140px' }}
          />
        )}
      </Paper>

      <Paper elevation={1} sx={{ margin: 'auto', mt: 1, mb: 1, p: 3 }}>
        <Typography variant="h3" color="primary">Suivre mes campagnes</Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            rows={campaigns}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 20, 50]}
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            sx={{ minHeight: '140px' }}
          />
        )}
      </Paper>

      <Dialog open={openDetails} onClose={() => setOpenDetails(false)} fullWidth maxWidth="md">
        <Helmet>
          <title>{`Campagne : ${selectedCampaign.name} (#${selectedCampaign.id}) - BEFIANA Connect`}</title>
        </Helmet>
        <DialogTitle color="primary">Campagne : {selectedCampaign.name} (#{selectedCampaign.id})</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} md={3}>
                <WidgetSMS name="Total SMS" nb={campaignStats.total_sms} color="#17a2b8" colorT="#ffffff" icon="SendAndArchive" />
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <WidgetSMS name="SMS Livrés" nb={campaignStats.sms_delivered} color="#ff8c00" colorT="#ffffff" icon="PhonelinkRing" />
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <WidgetSMS name="SMS Échoués" nb={campaignStats.sms_failed} color="#dc3545" colorT="#ffffff" icon="GppBad" />
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <WidgetSMS name="SMS Reçu" nb={campaignStats.sms_terminal === 0 ? '-' : ((campaignStats.sms_terminal/campaignStats.total_sms)*100).toFixed(2) + '%'} color="#4caf50" colorT="#ffffff" icon="CheckCircle" />
              </Grid>
            </Grid>
            <br />
            <Typography><b>Message:</b> {selectedCampaign.message}</Typography>
            <Typography><b>Ref. Liste :</b> {selectedCampaign.target_all_contacts ? 'Tous mes contacts' : `${selectedCampaign.list}`}</Typography>
            <Typography><b>Création :</b> {new Date(selectedCampaign.created_at).toLocaleString()}</Typography>
            <DisplayLaunchDate sendAt={selectedCampaign.send_at} />
            <Typography variant="subtitle2" color="secondary" mt={2} textAlign="center">Nous traitons l'envoi de messages en file d'attente en fonction du débit par seconde autorisé pour tous les utilisateurs. Vous pouvez suivre l'évolution de la campagne ici.</Typography>
          </Box>
        </DialogContent>
        <Button onClick={() => setOpenDetails(false)} color="primary">Fermer</Button>
      </Dialog>

      <Dialog open={openDetailsFuture} onClose={() => setOpenDetailsFuture(false)} fullWidth maxWidth="sm">
        <Helmet>
          <title>{`Campagne : ${selectedCampaign.name} (#${selectedCampaign.id}) - BEFIANA Connect`}</title>
        </Helmet>
        <DialogTitle color="primary">Campagne : {selectedCampaign.name} (#{selectedCampaign.id})</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Grid container direction='row' spacing={0} justifyContent="space-between" alignItems="center">
              <Grid item xs={6} sm={6} md={6}>
                <WidgetSMS name="Contact" nb={campaignInfos.recipients_count} color="#17a2b8" colorT="#ffffff" icon="ContactPhone" />
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <WidgetSMS name="SMS Solde" nb={campaignInfos.current_balance} color="#ff8c00" colorT="#ffffff" icon="ShoppingCart" />
              </Grid>
            </Grid>
            <br />
            <Typography variant="subtitle2" color="secondary" m={1}>{campaignInfos.message}</Typography>
            <Typography m={1}><b>Ref. Liste :</b> {selectedCampaign.target_all_contacts ? 'Tous mes contacts' : `${selectedCampaign.list}`} | <b>Message:</b> {selectedCampaign.message}</Typography>
            <Typography m={1}><b>Nombre de SMS nécessaires :</b> {selectedCampaign.message ? getSmsCount(getMessageLength(selectedCampaign.message)) * campaignInfos.recipients_count : 0}</Typography>

            <DisplayLaunchDate sendAt={selectedCampaign.send_at} />
            <Stack spacing={2}>
              <Button onClick={() => preLauchCampaign(selectedCampaign.id)} variant="outlined">Analyser</Button>
              <Button disabled={!canGoCampaign} onClick={() => LauchCampaign(selectedCampaign.id)} variant="contained">Lancer ma campagne</Button>
              <Button onClick={() => setOpenDetailsFuture(false)} variant="outlined">Annuler</Button>
            </Stack>

            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}
              open={goCampaign}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        </DialogContent>
        <Button onClick={() => delCampaign(selectedCampaign.id)} color="error">Supprimer</Button> <br />
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
          {msgCampaign}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: '#fff', zIndex: 9999 }}
        open={isLoadingStats} 
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default CampaignsList;
