import React, { useState, useEffect } from 'react';
import {
    AppBar, Toolbar, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    TextField, IconButton, Backdrop, CircularProgress, Paper, MenuItem, Select, FormControl, InputLabel, Box
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import ListIcon from '@mui/icons-material/List';
import ImportVouchers from './ImportVouchers';
import api from '../../../../services/api';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const VoucherGroupsManager = () => {
    const [voucherGroups, setVoucherGroups] = useState([]);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openImportDialog, setOpenImportDialog] = useState(false);
    const [openVoucherDialog, setOpenVoucherDialog] = useState(false);
    const [openProgressDialog, setOpenProgressDialog] = useState(false);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [openFailedDialog, setOpenFailedDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [groupDetails, setGroupDetails] = useState(null);
    const [groupForm, setGroupForm] = useState({ beneficiary: '', name: '' });
    const [vouchers, setVouchers] = useState([]);
    const [filteredVouchers, setFilteredVouchers] = useState([]);
    const [voucherGroupId, setVoucherGroupId] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [progress, setProgress] = useState(0);
    const [total, setTotal] = useState(0);
    const [successNumbers, setSuccessNumbers] = useState([]);
    const [failedNumbers, setFailedNumbers] = useState([]);

    useEffect(() => {
        fetchVoucherGroups();
        fetchBeneficiaries();
    }, []);

    const fetchVoucherGroups = async () => {
        setLoading(true);
        try {
            const response = await api.get('/api/ibondacha/voucher-groups/');
            setVoucherGroups(response.data);
        } catch (error) {
            console.error('Error fetching voucher groups:', error);
        }
        setLoading(false);
    };

    const fetchBeneficiaries = async () => {
        setLoading(true);
        try {
            const response = await api.get('/api/ibondacha/beneficiaries/');
            setBeneficiaries(response.data);
        } catch (error) {
            console.error('Error fetching beneficiaries:', error);
        }
        setLoading(false);
    };

    const fetchVouchers = async (groupId) => {
        setLoading(true);
        try {
            const response = await api.get(`/api/ibondacha/vouchers/filter/?voucher_group=${groupId}&status=1`);
            setVouchers(response.data);
            setFilteredVouchers(response.data);
        } catch (error) {
            console.error('Error fetching vouchers:', error);
        }
        setLoading(false);
    };

    const handleOpenDialog = () => {
        setGroupForm({ beneficiary: '', name: '' });
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setGroupDetails(null);
    };

    const handleOpenImportDialog = (groupId) => {
        setVoucherGroupId(groupId);
        setOpenImportDialog(true);
    };

    const handleCloseImportDialog = () => {
        setOpenImportDialog(false);
        setVoucherGroupId(null);
    };

    const handleOpenVoucherDialog = async (groupId) => {
        setVoucherGroupId(groupId);
        await fetchVouchers(groupId);
        setOpenVoucherDialog(true);
    };

    const handleCloseVoucherDialog = () => {
        setOpenVoucherDialog(false);
        setVoucherGroupId(null);
        setSearchText('');
        setFilteredVouchers([]);
    };

    const handleChange = (e) => {
        setGroupForm({ ...groupForm, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            if (groupDetails) {
                await api.put(`/api/ibondacha/voucher-groups/${groupDetails.id}/`, groupForm);
            } else {
                await api.post('/api/ibondacha/voucher-groups/', groupForm);
            }
            fetchVoucherGroups();
            handleCloseDialog();
        } catch (error) {
            console.error('Error submitting voucher group:', error);
        }
        setLoading(false);
    };

    const handleDelete = async (id) => {
        setLoading(true);
        try {
            await api.delete(`/api/ibondacha/voucher-groups/${id}/`);
            fetchVoucherGroups();
        } catch (error) {
            console.error('Error deleting voucher group:', error);
        }
        setLoading(false);
    };

    const handleViewDetails = async (id) => {
        setLoading(true);
        try {
            const response = await api.get(`/api/ibondacha/voucher-groups/${id}/`);
            setGroupDetails(response.data);
            setGroupForm({ beneficiary: response.data.beneficiary, name: response.data.name });
            setOpenDialog(true);
        } catch (error) {
            console.error('Error fetching voucher group details:', error);
        }
        setLoading(false);
    };

    const handleSearch = (e) => {
        setSearchText(e.target.value);
        const filtered = vouchers.filter((voucher) =>
            voucher.name_or_function.toLowerCase().includes(e.target.value.toLowerCase()) ||
            voucher.phone_number.includes(e.target.value) ||
            voucher.voucher_code.includes(e.target.value)
        );
        setFilteredVouchers(filtered);
    };

    const formatShortDate = (date) => dayjs(date).format('DD-MM-YYYY');


    const voucherColumns = [
        { field: 'id', headerName: 'ID', width: 90, flex: 1  },
        { field: 'name_or_function', headerName: 'Nom/Fonction', width: 150, flex: 1  },
        { field: 'phone_number', headerName: 'Numéro de Téléphone', width: 150, flex: 1  },
        { field: 'amount', headerName: 'Montant', width: 150, flex: 1, renderCell: (params) => parseInt(params.value, 10)  },
        // { field: 'status', headerName: 'Statut', width: 150 },
        { field: 'voucher_code', headerName: 'Code Bon', width: 150, flex: 1  },
        { field: 'validity', headerName: 'Validité', width: 200, flex: 1, renderCell: (params) => formatShortDate(params.value)  },
    ];

    const columns = [
        {
            field: 'import',
            headerName: 'Importer iBondacha',
            width: 150,
            renderCell: (params) => (
                <Button
                    startIcon={<UploadFileIcon />}
                    variant="contained"
                    color="ib1"
                    onClick={() => handleOpenImportDialog(params.id)}
                >
                    Importer
                </Button>
            ),
        },
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'name', headerName: 'Nom', width: 150 },
        { field: 'beneficiary', headerName: 'Bénéficiaire', width: 100 },
        {
            field: 'details',
            headerName: 'Détails',
            width: 90,
            renderCell: (params) => (
                <IconButton color="ib2" onClick={() => handleViewDetails(params.id)}>
                    <InfoIcon />
                </IconButton>
            ),
        },
        {
            field: 'viewVouchers',
            headerName: 'Voir',
            width: 90,
            renderCell: (params) => (
                <IconButton color="primary" onClick={() => handleOpenVoucherDialog(params.id)}>
                    <ListIcon />
                </IconButton>
            ),
        },
        {
            field: 'delete',
            headerName: 'Supp.',
            width: 90,
            renderCell: (params) => (
                <IconButton color="secondary" onClick={() => handleDelete(params.id)}>
                    <DeleteIcon />
                </IconButton>
            ),
        },
    ];

    const sendBulkSMS = async (phoneNumbers) => {
        setOpenProgressDialog(true);
        setTotal(phoneNumbers.length);
        setProgress(0);
        setSuccessNumbers([]);
        setFailedNumbers([]);
    
        for (let i = 0; i < phoneNumbers.length; i++) {
            try {
                await api.post('/api/ibondacha/sms/', { phone_number: phoneNumbers[i] });
                setSuccessNumbers((prev) => [...prev, phoneNumbers[i]]);
            } catch (error) {
                setFailedNumbers((prev) => [...prev, phoneNumbers[i]]);
            }
            setProgress((prev) => prev + 1);
            await new Promise(resolve => setTimeout(resolve, 3000));
        }
    };

    const handleSendBulkSMS = async () => {
        const phoneNumbers = filteredVouchers.map(voucher => voucher.phone_number);
        await sendBulkSMS(phoneNumbers);
    };

    return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div style={{ display: 'flex', flexDirection: 'column', padding: 16 }}>
            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <AppBar position="static" color='ib1' style={{ marginBottom: 16, opacity: 0.8 }}>
                <Toolbar>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        Gestion des Groupes de iBondacha
                    </Typography>
                    <Button variant='contained' color="ib2" startIcon={<AddIcon />} onClick={handleOpenDialog}>
                        Ajouter un Groupe
                    </Button>
                </Toolbar>
            </AppBar>
            <Paper elevation={1} sx={{ p: 3 }}>
                <div style={{ flexGrow: 1, width: '100%'  }}>
                    <DataGrid rows={voucherGroups} columns={columns} localeText={frFR.components.MuiDataGrid.defaultProps.localeText} />
                </div>
            </Paper>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{groupDetails ? 'Modifier le Groupe' : 'Ajouter un Groupe'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {groupDetails ? 'Modifier les détails du groupe de bons d\'achat.' : 'Entrez les détails du nouveau groupe de bons d\'achat.'}
                    </DialogContentText>
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="beneficiary-label">Bénéficiaire</InputLabel>
                        <Select
                            labelId="beneficiary-label"
                            name="beneficiary"
                            value={groupForm.beneficiary}
                            onChange={handleChange}
                        >
                            {beneficiaries.map((beneficiary) => (
                                <MenuItem key={beneficiary.id} value={beneficiary.reference}>
                                    {beneficiary.name} ({beneficiary.reference})
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="name"
                        label="Nom du Groupe"
                        fullWidth
                        value={groupForm.name}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        {groupDetails ? 'Mettre à jour' : 'Ajouter'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openImportDialog} onClose={handleCloseImportDialog}>
                <DialogTitle>Importer des iBondacha</DialogTitle>
                <DialogContent>
                    <ImportVouchers voucherGroupId={voucherGroupId} onSuccess={handleCloseImportDialog} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseImportDialog} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openVoucherDialog} onClose={handleCloseVoucherDialog} fullScreen>
                <DialogTitle>Bons d'Achat du Groupe</DialogTitle>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        value={searchText}
                        onChange={handleSearch}
                        placeholder="Rechercher des bons d'achat..."
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid rows={filteredVouchers} columns={voucherColumns} localeText={frFR.components.MuiDataGrid.defaultProps.localeText} />
                    </Box>
                    <Box sx={{mt:2}}>
                        <Button variant="contained" color="ib2" startIcon={<SendToMobileIcon />} onClick={handleSendBulkSMS}>
                          Envoyer SMS en Masse
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseVoucherDialog} color="primary" variant='contained'>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openProgressDialog} onClose={() => setOpenProgressDialog(false)} fullWidth maxWidth="sm">
                <DialogTitle>
                    <Button variant="text" color="ib2" startIcon={<SendToMobileIcon />} sx={{fontSize: '21px'}}>
                    Envoi de SMS en Masse
                    </Button>
                </DialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Box sx={{ display: 'block', margin: '0 auto'}}>
                                <img src="/ibondacha.png" width="200px" alt="Logo iBondacha" style={{ display: 'block', margin: '0 auto', paddingBottom: '25px'}} />
                        </Box>
                        <Typography variant="h6">Envoi des SMS : {progress}/{total}</Typography>
                        <CircularProgress variant="determinate" value={(progress / total) * 100} />
                        {progress === total && (
                            <Box mt={2}>
                                
                                <Typography variant="body2">Envoi de SMS terminé</Typography>
                                
                                <Typography variant="body2" color="primary">SMS réussis : {successNumbers.length}</Typography>
                                <Typography variant="body2" color="error">SMS échoués : {failedNumbers.length}</Typography>
                                <br />
                                <Button variant="contained" sx={{mr: 2}} onClick={() => setOpenSuccessDialog(true)} color='ib1'>
                                    Voir les numéros réussis
                                </Button>
                                <Button variant="contained" onClick={() => setOpenFailedDialog(true)} color='ib2'>
                                    Voir les numéros échoués
                                </Button>
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenProgressDialog(false)} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openSuccessDialog} onClose={() => setOpenSuccessDialog(false)} fullWidth maxWidth="md">
                <DialogTitle>
                    <Button variant="text" color="ib1" sx={{fontSize: '19px'}} disableElevation startIcon={<MarkEmailReadIcon />}>
                        Numéros Réussis
                    </Button>
                </DialogTitle>
                <DialogContent>
                    <Box display="flex" flexWrap="wrap" gap={2}>
                        {successNumbers.map((number, index) => (
                            <Paper key={index} sx={{ p: 1, m: 1, display: 'inline-block' }}>
                                <Typography>0{number}</Typography>
                            </Paper>
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenSuccessDialog(false)} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openFailedDialog} onClose={() => setOpenFailedDialog(false)} fullWidth maxWidth="md">
                <DialogTitle>
                    <Button variant="text" color="ib2" sx={{fontSize: '19px'}} disableElevation startIcon={<CancelScheduleSendIcon />}>
                        Numéros Échoués
                    </Button>
                </DialogTitle>
                <DialogContent>
                    <Box display="flex" flexWrap="wrap" gap={2}>
                        {failedNumbers.map((number, index) => (
                            <Paper key={index} sx={{ p: 1, m: 1, display: 'inline-block' }}>
                                <Typography>{number}</Typography>
                            </Paper>
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenFailedDialog(false)} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
        </LocalizationProvider>
    );
};

export default VoucherGroupsManager;
